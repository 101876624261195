<template>
  <div class="registered-box">
    <!-- 头部 -->
    <swipeTopVue
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTopVue>
    <!-- 填写个人信息 -->
    <div class="getUserInfo">
      <van-form @submit="onSubmit">
        <div class="formItem">
          <van-field v-show="false" v-model="userId" name="userId" />
        </div>
        <div class="formItem">
          <span class="titleName">姓名</span>
          <van-field
            v-model="name"
            name="name"
            placeholder="请输入用户姓名"
            :rules="[{ required: true, message: '请输入用户姓名' }]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">邮箱</span>
          <van-field v-model="email" name="email" placeholder="请输入邮箱" />
        </div>
        <div class="formItem">
          <span class="titleName">部门</span>
          <van-field
            v-model="department"
            name="department"
            placeholder="请输入手机号"
          />
        </div>
        <div class="formItem">
          <span class="titleName">省份</span>
          <van-field
            v-model="provinces"
            name="provinces"
            placeholder="请输入手机号"
          />
        </div>
        <div class="formItem">
          <span class="titleName">单位</span>
          <van-field v-model="unit" name="unit" placeholder="请输入手机号" />
        </div>
        <div class="formItem">
          <span class="titleName">地址</span>
          <van-field
            v-model="address"
            name="address"
            placeholder="请输入手机号"
          />
        </div>
        <div class="formItem">
          <span class="titleName">感兴趣的内容</span>
          <van-field
            v-model="contentOfInterest"
            name="contentOfInterest"
            placeholder="请输入手机号"
          />
        </div>
        <div class="formItem" style="display: flex; align-items: baseline">
          <span class="titleName" style="width: 40%">是否接收信息</span>
          <van-field style="background-color: transparent" name="radio">
            <template #input>
              <van-radio-group v-model="isInformation" direction="horizontal">
                <van-radio :name="true">接收</van-radio>
                <van-radio :name="false">不接收</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div style="margin: 60px 0; width: 80%">
          <van-button round block type="info" native-type="submit"
            >注册</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import swipeTopVue from "../../components/swipeTop.vue";
import { getuserInfo } from "../../api/path";
export default {
  components: {
    swipeTopVue,
  },
  data() {
    return {
      top_img:
        "https://sdg-minisite-prod-s3.s3.cn-northwest-1.amazonaws.com.cn/SDGimages/menu-background/ppjs-banner-4-1.jpg",
      ZHtitle: "个人信息",
      ENtitle: "REGISTERED",
      address: "", // 地址
      contentOfInterest: "", // 感兴趣的内容
      department: "", // 部门
      email: "", // 邮箱
      isInformation: true, //是否接收信息
      name: "", // 姓名
      phone: "", // 手机
      provinces: "", // 省份
      unit: "", // 单位
      userId: this.$cookies.get("userInfo"),
      codeTime: this.$cookies.get("codeTime") || 60,
      isClickCode: false,
    };
  },
  mounted() {
    if (this.$cookies.get("codeTime")) {
      this.isClickCode = true;
      this.getCode();
    }
  },
  methods: {
    onSubmit(value) {
      getuserInfo(JSON.stringify(value)).then((res) => {
        this.$toast({
          type: "loading",
          message: "提交中...",
          duration: 1500,
        });
        setTimeout(() => {
          if (res.code === 200) {
            this.$toast({
              type: "success",
              message: "已保存",
              duration: 1000,
            });
            setTimeout(() => {
              this.$router.push({
                path: "/loginPage",
              });
            }, 1000);
          }
        }, 1500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.registered-box {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .getUserInfo {
    overflow: scroll;
    height: 84%;
    background-color: #740709;
    .titleName {
      display: inline-block;
      margin: 40px 0 10px 0;
      color: #ffffff;
      .icon {
        color: red;
      }
    }
    .van-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .formItem {
        width: 90%;
      }
      .van-field {
        padding: 4px 16px;
        border-radius: 10px;
      }
    }
  }
}
/deep/ .van-button--small {
  width: 80px;
}
/deep/ .van-radio__label {
  color: #ffffff !important;
}
</style>
